(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('wishlistService', [
            '$http',
            function ($http) {

                var urlBase = 'api/wishlist/';

                this.getUnProcessWishlist = function (filters) {
                    return $http({
                        url: urlBase,
                        method: "GET",
                        params: filters
                    });
                };

                this.getStockItems = function (dto) {
                    return $http({
                        url: urlBase + 'get-stock-items',
                        method: 'POST',
                        data: dto
                    });
                };

                this.create = function (dto) {
                    return $http({
                        url: urlBase,
                        method: 'POST',
                        data: dto
                    });
                };

                this.wishlistProcess = function (id) {
                    return $http({
                        url: urlBase + "wishlist-process/" + id,
                        method: 'GET'
                    });
                };


                this.wishlistUnProcess = function (id) {
                    return $http({
                        url: urlBase + "wishlist-unprocess/" + id,
                        method: 'GET'
                    });
                };

                this.exportToExcel = function (dto) {
                    return $http({
                        url: urlBase + 'export-wishlist',
                        method: 'POST',
                        data: dto
                    });
                };

                this.delete = function (dto) {
                    return $http({
                        url: urlBase + 'delete',
                        method: 'POST',
                        data: dto
                    });
                };

                this.bulkProcess = function (dto) {
                    return $http({
                        url: urlBase + 'bulk-process',
                        method: 'POST',
                        data: dto
                    });
                };

                this.bulkUnProcess = function (dto) {
                    debugger;
                    return $http({
                        url: urlBase + 'bulk-unprocess',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
