(function (angular) {
    "use strict";

    angular.module('myApp').component('unProcessWishlist',
        {
            templateUrl: '/wishlist/views/unProcessWishlist.tpl.html',
            controller: ['$scope', 'rishviList', 'wishlistService', '$filter',
                function ($scope, rishviList, wishlistService, $filter) {

                    $scope.stockItemsSearch = {};
                    $scope.list = new rishviList();
                    $scope.list.url = 'api/wishlist/';
                    $scope.list.module = 'unProcessWishlist';
                    $scope.list.filters.wishlistStatus = 0;
                    $scope.isDeletedUnProcess = false;
                    //$scope.list.load();

                    loadUnprocessWishlist();

                    function loadUnprocessWishlist() {

                        if ($.fn.DataTable.isDataTable('#unprocessWishlist')) {
                            $('#unprocessWishlist').DataTable().clear().destroy();
                        }

                        wishlistService.getUnProcessWishlist($scope.list.filters).then(function (resp) {

                            if (resp.data.success) {
                                $scope.unProcessWishList = resp.data.data;
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                //window.location.reload();
                            }

                            BindDatable();
                            //setTimeout(function () {
                            //    $('[data-toggle="tooltip"]').tooltip();
                            //}, 500);
                        });
                    }

                    function BindDatable() {
                        setTimeout(function () {

                            var todayDate = moment().format('DD/MM/YYYY');
                            var table = $('#unprocessWishlist');

                            //table.dataTable().fnDestroy();

                            table.DataTable({
                                // DOM Layout settings
                                //order: [[0, 'desc']],
                                pageLength: 10,
                                scrollY: '70vh',
                                scrollX: true,
                                // sScrollX: $('#content').width(),
                                scrollCollapse: true,
                                //responsive: true,
                                //bAutoWidth:false,
                                bProcessing: true,
                                //data: dataJSONArray,
                                order: [[0, 'asc']],
                                columnDefs: [{
                                    targets: 'no-sort',
                                    orderable: false,
                                }],
                                destroy: true,
                                buttons: [
                                    {
                                        extend: 'excel',
                                        filename: 'Unprocess_Wishlist_' + todayDate,
                                        title: '',
                                        exportOptions: {
                                            columns: [0, 1, 2, 3, 4, 5]
                                        }
                                    }
                                    //{
                                    //    extend: 'csv',
                                    //    filename: 'Unprocess_Wishlist_' + $('#searchDate').val(),
                                    //    title: '',
                                    //    exportOptions: {
                                    //        columns: [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
                                    //    }
                                    //}
                                ]
                                //buttons: [
                                //    'copyHtml5',
                                //    'excelHtml5',
                                //    'csvHtml5',
                                //    'pdfHtml5'
                                //]
                            });
                        }, 500);
                    }


                    $('.group-checkable').change(function () {
                        var set = $('#unprocessWishlist').find('td:first-child .checkable');
                        var checked = $(this).is(':checked');

                        $(set).each(function () {
                            if (checked) {
                                $(this).prop('checked', true);
                                $(this).closest('tr').addClass('active');
                            }
                            else {
                                $(this).prop('checked', false);
                                $(this).closest('tr').removeClass('active');
                            }
                        });
                    });

                    $('#unprocessWishlist').on('change', 'tbody tr .selectpocheckbox', function () {
                        //if ($('#unprocessWishlist').find('tr').find('td').find('input[type=checkbox]').is(':checked'))
                        //    $scope.isDeletedUnProcess = true;
                        //else
                        //    $scope.isDeletedUnProcess = false;

                        $(this).parents('tr').toggleClass('active');
                    });

                    $scope.deleteWhishlistOrder = function () {

                        $scope.deleteDto = {};
                        $scope.deleteDto.ids = [];

                        var set = $('#unprocessWishlist').find('td:first-child .checkable');

                        $(set).each(function () {
                            var checked = $(this).is(':checked');
                            if (checked) {
                                $scope.deleteDto.ids.push($(this).val());
                            }
                        });

                        if ($scope.deleteDto.ids.length > 0) {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You want to delete selected WhishList?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!"
                            }).then(function (result) {
                                if (result.value) {
                                    if ($scope.deleteDto.ids.length > 0) {
                                        wishlistService.delete($scope.deleteDto).then(function (resp) {
                                            Swal.fire({
                                                position: "top-right",
                                                icon: resp.data.messageType === 1 ? "success" : "error",
                                                title: resp.data.message,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });
                                            if (resp.data.success) {
                                                loadUnprocessWishlist();
                                            }
                                        });
                                    }
                                    else {
                                        Swal.fire({
                                            position: "top-right",
                                            icon: "error",
                                            title: "Select atleast one item to delete!",
                                            showConfirmButton: false,
                                            timer: 2000
                                        });
                                    }
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                position: "top-right",
                                icon: "error",
                                title: "Select atleast one item to delete!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    }

                    $scope.processWhishlist = function () {

                        $scope.processDto = {};
                        $scope.processDto.ids = [];

                        var set = $('#unprocessWishlist').find('td:first-child .checkable');

                        $(set).each(function () {
                            var checked = $(this).is(':checked');
                            if (checked) {
                                $scope.processDto.ids.push($(this).val());
                            }
                        });

                        if ($scope.processDto.ids.length > 0) {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You want to process selected WhishList?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!"
                            }).then(function (result) {
                                if (result.value) {
                                    if ($scope.processDto.ids.length > 0) {
                                        wishlistService.bulkProcess($scope.processDto).then(function (resp) {
                                            Swal.fire({
                                                position: "top-right",
                                                icon: resp.data.messageType === 1 ? "success" : "error",
                                                title: resp.data.message,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });
                                            if (resp.data.success) {
                                                loadUnprocessWishlist();
                                            }
                                        });
                                    }
                                    else {
                                        Swal.fire({
                                            position: "top-right",
                                            icon: "error",
                                            title: "Select atleast one item to process!",
                                            showConfirmButton: false,
                                            timer: 2000
                                        });
                                    }
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                position: "top-right",
                                icon: "error",
                                title: "Select atleast one item to process!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    }


                    $scope.addNewItem = function () {
                        $scope.stockItemsSearch.keyWord = '';
                        $scope.stockItems = [];
                        $('#addNewPOStockIemModal').modal('show');
                    }

                    function getSearchStockItems() {

                        if ($scope.stockItemsSearch.keyWord.length < 3) {
                            $scope.isSearchError = "'Search' length must be three or more character.";
                            return;
                        }
                        $scope.isSearchError = '';

                        $scope.stockItemsSearch.locationId = "00000000-0000-0000-0000-000000000000";
                        $scope.stockItemsSearch.entriesPerPage = 50;
                        $scope.stockItemsSearch.pageNumber = 1;
                        $scope.stockItemsSearch.excludeComposites = false;
                        $scope.stockItemsSearch.excludeVariations = true;
                        $scope.stockItemsSearch.excludeBatches = true;

                        wishlistService.getStockItems($scope.stockItemsSearch).then(function (resp) {

                            $scope.stockItems = resp.data.data;
                        });
                    }

                    $scope.getStockItems = function () {
                        getSearchStockItems();
                    }

                    $scope.getStockItemsEnter = function (keyEvent) {
                        if (keyEvent.which === 13) {
                            getSearchStockItems();
                        }
                    }

                    $scope.selectStockItems = function (stockItemId) {
                        
                        $scope.stockItem = $filter('filter')($scope.stockItems.data, { 'stockItemId': stockItemId });
                        debugger;
                        $scope.wishlistCreate = {};
                        $scope.wishlistCreate.stockItemId = stockItemId;
                        $scope.wishlistCreate.itemNumber = $scope.stockItem[0].itemNumber;
                        $scope.wishlistCreate.itemTitle = $scope.stockItem[0].itemTitle;
                        $scope.wishlistCreate.barcodeNumber = $scope.stockItem[0].barcodeNumber;
                        $scope.wishlistCreate.level = $scope.stockItem[0].minimumLevel;
                        $scope.wishlistCreate.salesLast = $scope.stockItem[0].salesInLast;
                        $scope.wishlistCreate.consumption = $scope.stockItem[0].consumption;
                        $scope.wishlistCreate.wishlistStatus = 0;

                        wishlistService.create($scope.wishlistCreate).then(function (resp) {
                            if (resp.data.data === 0) {
                                Swal.fire({
                                    position: "top-right",
                                    icon: "warning",
                                    title: "Item already added in wishlist!",
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: "success",
                                    title: "Item added in wishlist!",
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                                //$scope.list.load();
                                loadUnprocessWishlist();
                            }
                        });

                        //$("#tblStockItems input[type=checkbox]:checked").each(function () {
                        //    var row = $(this).closest("tr")[0];
                        //    var itemNumber = row.cells[1].innerHTML;
                        //    $scope.stockItem = $filter('filter')($scope.stockItems.data, { 'itemNumber': itemNumber });
                        //    $scope.filterSelectedStockItem = $filter('filter')($scope.selectedStockItems, { 'itemNumber': itemNumber });
                        //    if ($scope.filterSelectedStockItem.length <= 0) {
                        //        $scope.stockItem[0].quantity = 1;
                        //        //$scope.stockItem[0].cartonQty = 1;
                        //        $scope.selectedStockItems.push($scope.stockItem[0]);
                        //    }
                        //});

                        //$scope.totalCbm = 0;
                        //$scope.totalLineTotal = 0;
                        //$scope.selectedStockItems.filter(function (el) {
                        //    $scope.totalCbm += el.cartonQty * el.cbm;
                        //    $scope.totalLineTotal += el.quantity * el.purchasePrice;
                        //});

                        //$scope.rishvi.dto.amount = $scope.rishvi.dto.conversionRate * $scope.totalLineTotal;
                    };

                    $scope.processStockItem = function (id, $event) {
                        wishlistService.wishlistProcess(id).then(function (resp) {
                           
                            if (resp.data.success) {
                                Swal.fire({
                                    position: "top-right",
                                    icon: "success",
                                    title: "Item process successfully!",
                                    showConfirmButton: false,
                                    timer: 2000
                                }); 
                                //$scope.list.load();
                                loadUnprocessWishlist();
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: "error",
                                    title: "Sorry, Record not found. Please Try again!",
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    }

                    $scope.exportToExcel = function () {
                        $("#unprocessWishlist").DataTable().button('.buttons-excel').trigger();
                    }

                    //$scope.exportToExcel = function () {
                    //    $scope.filterData = {};
                    //    $scope.filterData = $scope.list.filters;
                        
                    //    wishlistService.exportToExcel($scope.filterData).then(function (resp) {

                    //        if (resp.data.success) {
                    //            var win = window.open(resp.data.data, '_blank');
                    //            if (win) {
                    //                //Browser has allowed it to be opened
                    //                win.focus();
                    //            } else {
                    //                //Browser has blocked it
                    //                Swal.fire({
                    //                    position: "top-right",
                    //                    icon: "warning",
                    //                    title: "Please allow popups for this website.",
                    //                    showConfirmButton: false,
                    //                    timer: 2000
                    //                });
                    //            }
                    //        }
                    //        else {
                    //            Swal.fire({
                    //                position: "top-right",
                    //                icon: resp.data.messageType === 1 ? "success" : "error",
                    //                title: resp.data.message,
                    //                showConfirmButton: false,
                    //                timer: 2000
                    //            });
                    //        }
                    //    });
                    //};
                }]
        });

}(window.angular));