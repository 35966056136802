(function (angular) {
    "use strict";

    angular.module('myApp').component('adminDashboard',
        {
            templateUrl: '/home/views/home.tpl.html',
            controller: ['$scope', '$state', 'dashboardService',
                function ($scope, $state, dashboardService) {

                    //dashboardService.statisticsCount().then(function (resp) {
                    //    $scope.statisticsCount = resp.data;
                    //});

                    //dashboardService.getRecentPractices().then(function (resp) {
                    //    $scope.practices = resp.data.data;
                    //});

                    //dashboardService.getRecentOrders().then(function (resp) {
                    //    $scope.orders = resp.data.data;
                    //});

                    //$scope.viewAllPractices = function () {
                    //    $state.go("practices");
                    //};

                }]
        });

}(window.angular));
