(function (angular) {
    "use strict";

    angular.module('myApp').component('login',
        {
            templateUrl: '/auth/views/login.tpl.html',
            controller: 'LoginController'
        });

    angular.module('myApp').controller('LoginController',
        [
            '$scope', '$http', 'authService', '$rootScope', 'localStorageService', '$state', '$cookies',
            function ($scope, $http, authService, $rootScope, localStorageService, $state, $cookies) {

                $scope.dto = {};
                $scope.result = {};

                if (localStorageService.get(tokenName) != null) {
                    $state.go('home');
                }

                if ($rootScope.result) {
                    $scope.result = $rootScope.result;
                    $rootScope.result = null;
                }

                $scope.submit = function () {
                    authService.login($scope.dto)
                        .then(function (resp) {
                            $scope.result = resp.data;

                            Swal.fire({
                                position: "top-right",
                                icon: resp.data.messageType === 1 ? "success" : "error",
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 2000
                            });

                            if (resp.data.success) {
                                var token = resp.data.data;
                                localStorageService.set(tokenName, token);
                                $cookies.put('auth', token);

                              $state.go('home');
                            }

                        });
                };
            }
        ]);

}(window.angular));
