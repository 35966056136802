(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('uploadS3FileService', [
            '$http','$q', 
            function ($http, $q) {

                AWS.config.region = 'eu-west-2';
                AWS.config.update({ accessKeyId: 'AKIAZCHLP2HSC4DKRV5K', secretAccessKey: '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' });

                var bucket = new AWS.S3({ params: { Bucket: 'shipping-integration-file-storage', maxRetries: 10 }, httpOptions: { timeout: 360000 } });
                
                this.Progress = 0;
                this.Upload = function (file, fileName) {
                    var deferred = $q.defer();
                    var params = { Bucket: 'shipping-integration-file-storage', Key: 'McakyPharmacy/' + fileName, ContentType: file.type, Body: file };
                    var options = {
                        // Part Size of 10mb
                        partSize: 10 * 1024 * 1024,
                        queueSize: 1,
                        // Give the owner of the bucket full control
                        //ACL: 'bucket-owner-full-control'
                        ACL: 'public-read'
                    };
                    var uploader = bucket.upload(params, options, function (err, data) {
                        if (err) {
                            deferred.reject(err);
                        }
                        deferred.resolve();
                    });
                    uploader.on('httpUploadProgress', function (event) {
                        deferred.notify(event);
                    });

                    return deferred.promise;
                };
            }
        ]);

}(window.angular));
