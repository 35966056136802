(function (angular) {
  "use strict";

  angular.module('myApp')
    .service('authService',
      [
        '$http', function ($http) {

          var urlBase = 'api/auth/';

          this.login = function (loginDto) {
            return $http({
              url: urlBase + 'login',
              skipAuthorization: true,
              method: 'POST',
              data: loginDto
            });
          };
        }
      ]);

}(window.angular));
