(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('wishlistView', {
                parent: 'screen',
                url: '/wishlist',
                redirectTo: 'unProcessWishlist',
                component: 'wishlistView'
                //data: {
                //    permissions: ['orders']
                //}
            })
                .state('unProcessWishlist', {
                    parent: 'wishlistView',
                    url: '/unprocess',
                    component: 'unProcessWishlist'
                    //data: {
                    //    permissions: ['orders']
                    //}
                })
                .state('processWishlist', {
                    parent: 'wishlistView',
                    url: '/process',
                    component: 'processWishlist'
                    //data: {
                    //    permissions: ['orders']
                    //}
                });
        }
    ]);

})(angular);
