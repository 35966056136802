(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('poScreenService', [
            '$http',
            function ($http) {

                var urlBase = 'api/purchase-order/';

                this.getSupplierDropdown = function () {
                    return $http({
                        url: urlBase + 'get-all-supplier',
                        method: 'GET'
                    });
                };

                this.getLocationDropdown = function () {
                    return $http({
                        url: urlBase + 'get-all-location',
                        method: 'GET'
                    });
                };

                this.list = function (filters) {
                    return $http({
                        url: urlBase + 'get',
                        data: filters,
                        method: 'POST'
                    });
                };

                //this.create = function (dto) {
                //    return $http({
                //        url: urlBase,
                //        method: 'POST',
                //        data: dto
                //    });
                //};

                this.byId = function (id) {
                    return $http({
                        url: urlBase + id,
                        method: 'GET'
                    });
                };

                this.edit = function (id, dto) {
                    return $http({
                        url: urlBase + id,
                        method: 'PUT',
                        data: dto
                    });
                };

                //this.editList = function (id, dto) {
                //    return $http({
                //        url: urlBase +'edit-from-list/'+ id,
                //        method: 'PUT',
                //        data: dto
                //    });
                //};

                this.getCurrencyDropdown = function () {
                    return $http({
                        url: urlBase + 'get-all-currency',
                        method: 'GET'
                    });
                };

                this.getStockItems = function (dto) {
                    return $http({
                        url: 'api/wishlist/get-stock-items',
                        method: 'POST',
                        data: dto
                    });
                };

                this.getParentStockItems = function (dto) {
                    return $http({
                        url: urlBase + 'get-parent-stock-items',
                        method: 'POST',
                        data: dto
                    });
                };

                this.ImportPurchaseOrder = function (dto) {
                    return $http({
                        url: 'api/file/upload',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
