(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('poScreen', {
                parent: 'screen',
                url: '/po-screen',
                component: 'poScreen'
            })
            .state('poScreenEdit', {
                parent: 'screen',
                url: '/po-screen/edit/{id}',
                component: 'poScreenEdit'
            });
        }
    ]);

})(angular);
