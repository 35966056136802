(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('dashboardService', [
            '$http',
            function ($http) {

                var urlBase = 'api/admin/dashboard/';

                //this.statisticsCount = function () {
                //    return $http({
                //        url: urlBase + 'get-all-statistics-count',
                //        method: 'GET'
                //    });
                //};

                //this.getRecentPractices = function () {
                //    return $http({
                //        url: urlBase + 'get-practice-list',
                //        method: 'GET'
                //    });
                //};

                //this.getRecentOrders = function () {
                //    return $http({
                //        url: urlBase + 'get-recent-orders',
                //        method: 'GET'
                //    });
                //};

            }
        ]);

}(window.angular));
