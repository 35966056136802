(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('auth',
        {
        })
        .state('login',
          {
            parent: 'admin',
            url: '/auth/login',
            component: 'login'
          })
        .state('register',
          {
            parent: 'auth',
            url: '/admin/register',
            component: 'register'
          })
        .state('logout',
          {
            parent: 'auth',
            url: '/auth/logout'
          });
    }]);

})(angular);
