(function (angular) {
    "use strict";

    angular.module('myApp').component('poScreenEdit', {
        templateUrl: '/poScreen/views/poScreenEdit.tpl.html',
        controller: [
            '$scope', 'poScreenService', 'rishviEdit', '$filter', "$state",
            function ($scope, poScreenService, rishviEdit, $filter, $state) {

                $scope.rishvi = new rishviEdit();
                $scope.rishvi.service = poScreenService;
                $scope.rishvi.listRoute = 'poScreen';
                $scope.rishvi.parentObj = true;
                $scope.totalLineTotal = 0;
                $scope.stockItemsSearch = {};
                $scope.stockItems = [];
                $scope.rishvi.dto.purchaseOrderHeader = {};
                $scope.rishvi.dto.purchaseOrderExtendedPropertyById = {};
                $scope.rishvi.dto.purchaseOrderHeader.currency = "USD";
                $scope.rishvi.load();

                $scope.rishvi.onAfterLoad = function () {
                    
                    var clonedata = clone($scope.rishvi.dto);
                    var convertDto = DotObject.object(clonedata);
                    var newconvertDto = convertDto.purchaseOrderStockItem;
                    //$scope.rishvi.dto.purchaseOrderStockItem = [];
                    //$scope.rishvi.dto.purchaseOrderStockItem = $scope.convertDto.purchaseOrderStockItem;

                    $scope.rishvi.dto = DotObject.object(clonedata);
                    $scope.rishvi.dto.purchaseOrderStockItem = [];
                    $scope.rishvi.dto = DotObject.dot($scope.rishvi.dto);
                    $scope.rishvi.dto.purchaseOrderStockItem = newconvertDto;

                    $scope.totalLineTotal = $scope.rishvi.dto['purchaseOrderHeader.totalCost'];
                    
                    //if ($scope.rishvi.dto.purchaseOrderStockItem.length <= 0) {
                    //    setTimeout(function () {
                    //        $("#btnSaveService").prop('disabled', true);
                    //    }, 500);
                    //}
                }

                //this.$onInit = function () {

                //};        

                $scope.supplieres = [];
                //poScreenService.getSupplierDropdown().then(function (resp) {
                //    $scope.supplieres = resp.data;
                //});
                poScreenService.getLocationDropdown().then(function (resp) {
                    $scope.locations = resp.data;
                });

                poScreenService.getCurrencyDropdown().then(function (resp) {
                    $scope.currencies = resp.data;
                    //$scope.currencies.filter(function (el) {
                    //    if (el.currency === "USD") {
                    //        $scope.rishvi.dto.conversionRate = el.conversionRate;
                    //    }
                    //});
                });

                $scope.addNewItem = function () {
                    $scope.stockItemsSearch.keyWord = '';
                    $scope.stockItems = [];
                    $('#addNewPOStockIemModal').modal('show');
                }

                $scope.getStockItems = function () {
                    getSearchStockItems();
                }

                $("#stockItemSearchKeyword").keyup(function (event) {
                    if (event.keyCode === 13) {
                        getSearchStockItems();
                    }
                });

                function getSearchStockItems() {

                    if ($scope.stockItemsSearch.keyWord.length < 3) {
                        $scope.isSearchError = "'Search' length must be three or more character.";
                        return;
                    }
                    $scope.isSearchError = '';

                    $scope.stockItemsSearch.locationId = "00000000-0000-0000-0000-000000000000";
                    $scope.stockItemsSearch.entriesPerPage = 50;
                    $scope.stockItemsSearch.pageNumber = 1;
                    $scope.stockItemsSearch.excludeComposites = false;
                    $scope.stockItemsSearch.excludeVariations = true;
                    $scope.stockItemsSearch.excludeBatches = true;

                    poScreenService.getStockItems($scope.stockItemsSearch).then(function (resp) {
                        
                        $scope.stockItems = resp.data.data;
                    });
                }

                $scope.getStockItemsEnter = function (keyEvent) {
                    if (keyEvent.which === 13) {
                        getSearchStockItems();
                    }
                }

                $scope.selectStockItems = function () {
                    
                    //var enableSaveButton = false;
                    $("#tblStockItems input[type=checkbox]:checked").each(function () {
                        
                        //enableSaveButton = true;
                        //var row = $(this).closest("tr")[0];
                        //var stockItemId = row.cells[2].innerHTML;
                        var stockItemId = $(this).data('stockid');
                        $scope.stockItem = $filter('filter')($scope.stockItems.data, { 'stockItemId': stockItemId });

                        if ($scope.stockItem.length > 0 && $scope.stockItem[0].isCompositeParent) {

                            $scope.parentStockItemsSearch = {};
                            $scope.parentStockItemsSearch.inventoryItemId = $scope.stockItem[0].stockItemId;

                            poScreenService.getParentStockItems($scope.parentStockItemsSearch).then(function (resp) {

                                $scope.parentStockItems = resp.data.data;
                                
                                $scope.parentStockItems.filter(function (parentStockItem) {
                                   
                                    $scope.filterSelectedStockItem = $filter('filter')($scope.rishvi.dto.purchaseOrderStockItem, { 'stockItemId': parentStockItem.linkedStockItemId });
                                    if ($scope.filterSelectedStockItem.length <= 0) {
                                        $scope.addNewStockItem = {};
                                        $scope.addNewStockItem.sku = parentStockItem.sku;
                                        $scope.addNewStockItem.itemTitle = parentStockItem.itemTitle;
                                        $scope.addNewStockItem.stockItemId = parentStockItem.linkedStockItemId;
                                        $scope.addNewStockItem.quantity = 1;
                                        $scope.addNewStockItem.packQuantity = 1;
                                        $scope.addNewStockItem.packSize = 1;
                                        $scope.addNewStockItem.cost = parentStockItem.purchasePrice;
                                        $scope.addNewStockItem.taxRate = 0; //parentStockItem.taxRate;
                                        $scope.addNewStockItem.purchaseOrderStockItemType = 1;

                                        $scope.rishvi.dto.purchaseOrderStockItem.push($scope.addNewStockItem);
                                    }                                    
                                });
                            });
                        }
                        else {
                            $scope.filterSelectedStockItem = $filter('filter')($scope.rishvi.dto.purchaseOrderStockItem, { 'stockItemId': stockItemId });
                            if ($scope.filterSelectedStockItem.length <= 0) {
                                //$scope.stockItem[0].quantity = 1;
                                //$scope.stockItem[0].cartonQty = 1;
                                //$scope.selectedStockItems.push($scope.stockItem[0]);
                                // write code add new stock item
                                $scope.addNewStockItem = {};
                                $scope.addNewStockItem.sku = $scope.stockItem[0].itemNumber;
                                $scope.addNewStockItem.itemTitle = $scope.stockItem[0].itemTitle;
                                $scope.addNewStockItem.stockItemId = $scope.stockItem[0].stockItemId;
                                $scope.addNewStockItem.quantity = 1;
                                $scope.addNewStockItem.packQuantity = 1;
                                $scope.addNewStockItem.packSize = 1;
                                $scope.addNewStockItem.cost = $scope.stockItem[0].purchasePrice;
                                $scope.addNewStockItem.taxRate = $scope.stockItem[0].taxRate;
                                $scope.addNewStockItem.purchaseOrderStockItemType = 1;
                                $scope.rishvi.dto.purchaseOrderStockItem.push($scope.addNewStockItem);
                            }
                        }                        
                    });

                    $scope.totalLineTotal = 0;
                    $scope.rishvi.dto.purchaseOrderStockItem.filter(function (el) {

                        $scope.totalLineTotal += el.quantity * el.cost;
                    });

                    var currency = $scope.rishvi.dto["purchaseOrderHeader.currency"];
                    var conversionRate = 0;
                    $scope.currencies.filter(function (el) {
                        if (el.currency === currency) {
                            conversionRate = el.conversionRate;
                        }
                    });

                    if ($scope.rishvi.dto["purchaseOrderHeader.defaultCurrency"] === $scope.rishvi.dto["purchaseOrderHeader.currency"]) {
                        $scope.rishvi.dto["purchaseOrderHeader.totalCost"] = $scope.totalLineTotal.toFixed(2);
                    }
                    else {
                        $scope.rishvi.dto["purchaseOrderHeader.totalCost"] = (conversionRate * $scope.totalLineTotal).toFixed(2);
                    }

                    //if (enableSaveButton) {
                    //    $("#btnSaveService").prop('disabled', false);
                    //}
                    //else {
                    //    $("#btnSaveService").prop('disabled', true);
                    //}
                };

                $scope.deleteItem = function () {
                    $("#tblSelectedStockItems input[type=checkbox]:checked").each(function () {
                        
                        //var row = $(this).closest("tr")[0];
                        //var stockItemId = row.cells[2].innerHTML;
                        var stockItemId = $(this).data('stockid');

                        $scope.rishvi.dto.purchaseOrderStockItem.filter(function (el) {
                            if (el.stockItemId === stockItemId) {
                               
                                var index = $scope.rishvi.dto.purchaseOrderStockItem.indexOf(el);
                                $scope.rishvi.dto.purchaseOrderStockItem.splice(index, 1);

                                if (el.purchaseOrderStockItemType === 2) {
                                    $scope.rishvi.dto.deleteStockItems.push(el.purchaseItemId);
                                }
                            }
                        });

                        $scope.totalLineTotal = 0;
                        $scope.rishvi.dto.purchaseOrderStockItem.filter(function (el) {
                            $scope.totalLineTotal += el.quantity * el.cost;
                        });

                        var currency = $scope.rishvi.dto["purchaseOrderHeader.currency"];
                        var conversionRate = 0;
                        $scope.currencies.filter(function (el) {
                            if (el.currency === currency) {
                                conversionRate = el.conversionRate;
                            }
                        });

                        if ($scope.rishvi.dto["purchaseOrderHeader.defaultCurrency"] === $scope.rishvi.dto["purchaseOrderHeader.currency"]) {
                            $scope.rishvi.dto["purchaseOrderHeader.totalCost"] = $scope.totalLineTotal.toFixed(2);
                        }
                        else {
                            $scope.rishvi.dto["purchaseOrderHeader.totalCost"] = (conversionRate * $scope.totalLineTotal).toFixed(2);
                        }
                    });

                    //if ($scope.rishvi.dto.purchaseOrderStockItem.length > 0) {
                    //    $("#btnSaveService").prop('disabled', false);
                    //}
                    //else {
                    //    $("#btnSaveService").prop('disabled', true);
                    //}
                }

                $scope.currencyChange = function () {
                    var currency = $scope.rishvi.dto["purchaseOrderHeader.currency"];
                    var conversionRate = 0;
                    $scope.currencies.filter(function (el) {
                        if (el.currency === currency) {
                            conversionRate = el.conversionRate;
                        }
                    });

                    if ($scope.rishvi.dto["purchaseOrderHeader.defaultCurrency"] === $scope.rishvi.dto["purchaseOrderHeader.currency"]) {
                        $scope.rishvi.dto["purchaseOrderHeader.totalCost"] = $scope.totalLineTotal.toFixed(2);
                    }
                    else {
                        $scope.rishvi.dto["purchaseOrderHeader.totalCost"] = (conversionRate * $scope.totalLineTotal).toFixed(2);
                    }
                }

            }
        ]
    });

}(window.angular));
