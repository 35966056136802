(function (angular) {
  "use strict";

  angular.module('myApp').component('wishlistView', {
    templateUrl: '/wishlist/views/wishlistView.tpl.html',
    controller: [
      '$scope', '$state',
      function ($scope, $state) {
        
      }]
  });

}(window.angular));
