(function (angular) {
    "use strict";

    angular.module('myApp').component('poScreen',
        {
            templateUrl: '/poScreen/views/poScreen.tpl.html',
            controller: ['$scope', '$state', 'rishviCreate', 'poScreenService', 'Upload', '$timeout', '$filter', 'uploadS3FileService',
                function ($scope, $state, rishviCreate, poScreenService, Upload, $timeout, $filter, uploadS3FileService) {

                    $scope.rishvi = new rishviCreate();
                    $scope.supplieres = [];
                    $scope.locations = [];
                    $scope.purchaseOrderFilterDto = {};
                    var start = moment().subtract(180, 'days');
                    var end = moment();

                    $('#purchaseDateRange').daterangepicker({
                        buttonClasses: ' btn',
                        applyClass: 'btn-primary',
                        cancelClass: 'btn-secondary',

                        startDate: start,
                        endDate: end,
                        ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Last 3 Months': [moment().subtract(3, 'month'), moment().subtract(0, 'month')],
                            'Last 6 Months': [moment().subtract(6, 'month'), moment().subtract(0, 'month')],
                            'Last 1 Year': [moment().subtract(1, 'year'), moment().subtract(0, 'year')]
                        }
                    }, function (start, end, label) {
                        $('#purchaseDateRange .form-control').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
                    });

                    $('#searchDate').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));

                    poScreenService.getSupplierDropdown().then(function (resp) {
                        $scope.supplieres = resp.data;
                    });

                    poScreenService.getLocationDropdown().then(function (resp) {
                        $scope.locations = resp.data;
                    });

                    $scope.clearSearch = function () {
                        $scope.rishvi = new rishviCreate();
                    };

                    $scope.searchPo = function () {
                        loadPurchaseOrder();
                    };

                    loadPurchaseOrder();

                    function loadPurchaseOrder() {

                        if ($.fn.DataTable.isDataTable('#poScreen')) {
                            $('#poScreen').DataTable().clear().destroy();
                        }

                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        $scope.rishvi.dto.dateFrom = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.rishvi.dto.dateTo = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        $scope.rishvi.dto.supplier = [];
                        if ($scope.rishvi.dto.fkSupplierId !== '' && $scope.rishvi.dto.fkSupplierId !== null && $scope.rishvi.dto.fkSupplierId !== undefined) {
                            $scope.rishvi.dto.supplier.push($scope.rishvi.dto.fkSupplierId);
                        }
                        $scope.rishvi.dto.location = [];
                        if ($scope.rishvi.dto.fkLocationId !== '' && $scope.rishvi.dto.fkLocationId !== null && $scope.rishvi.dto.fkLocationId !== undefined) {
                            $scope.rishvi.dto.location.push($scope.rishvi.dto.fkLocationId);
                        }

                        $scope.purchaseOrderFilterDto.searchParameter = {};
                        $scope.purchaseOrderFilterDto.searchParameter.location = [];
                        $scope.purchaseOrderFilterDto.searchParameter.pageNumber = 1;
                        $scope.purchaseOrderFilterDto.searchParameter.referenceLike = '';
                        $scope.purchaseOrderFilterDto.searchParameter.dateFrom = $scope.rishvi.dto.dateFrom;
                        $scope.purchaseOrderFilterDto.searchParameter.dateTo = $scope.rishvi.dto.dateTo;
                        $scope.purchaseOrderFilterDto.searchParameter.supplier = $scope.rishvi.dto.supplier;
                        $scope.purchaseOrderFilterDto.searchParameter.location = $scope.rishvi.dto.location;
                        $scope.purchaseOrderFilterDto.searchParameter.status = $scope.rishvi.dto.status === undefined ? "" : $scope.rishvi.dto.status;

                        poScreenService.list($scope.purchaseOrderFilterDto).then(function (resp) {

                            if (resp.data.success) {
                                $scope.purchaseOrderList = resp.data.data;

                                BindDatable();
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                BindDatable();
                                //window.location.reload();
                            }


                            //setTimeout(function () {
                            //    $('[data-toggle="tooltip"]').tooltip();
                            //}, 500);
                        });
                    }

                    function BindDatable() {
                        setTimeout(function () {

                            var table = $('#poScreen');

                            //table.dataTable().fnDestroy();

                            table.DataTable({
                                // DOM Layout settings
                                //order: [[0, 'desc']],
                                pageLength: 10,
                                scrollY: '70vh',
                                scrollX: true,
                                // sScrollX: $('#content').width(),
                                scrollCollapse: true,
                                //responsive: true,
                                //bAutoWidth:false,
                                bProcessing: true,
                                //data: dataJSONArray,
                                order: [[4, 'asc']],
                                columnDefs: [{
                                    targets: 'no-sort',
                                    orderable: false,
                                }],
                                destroy: true
                                //buttons: [
                                //    {
                                //        extend: 'excel',
                                //        filename: 'Purchase_Order_' + $('#searchDate').val(),
                                //        title: '',
                                //        exportOptions: {
                                //            columns: [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
                                //        }
                                //    },
                                //    {
                                //        extend: 'csv',
                                //        filename: 'Purchase_Order_' + $('#searchDate').val(),
                                //        title: '',
                                //        exportOptions: {
                                //            columns: [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
                                //        }
                                //    }
                                //]
                                //buttons: [
                                //    'copyHtml5',
                                //    'excelHtml5',
                                //    'csvHtml5',
                                //    'pdfHtml5'
                                //]
                            });
                        }, 500);
                    }

                    $scope.EditPurchaseOrder = function (pkPurchaseID) {
                        $state.go("poScreenEdit", { id: pkPurchaseID });
                    }

                    $scope.fileUrl = null;
                    $scope.uploadFiles = function (file, errFiles) {

                        $scope.f = file;
                        $scope.errFile = errFiles && errFiles[0];
                        $scope.upload = {};
                        $scope.upload.importPOFile = file;
                        $scope.importPOObject = {};
                        
                        if (file != null) {
                            var extension = file.name.substr(file.name.lastIndexOf('.') + 1)
                            var test = file.name.replace("." + extension, "");
                            var fileName = test + '-' + Date.now() + "." + extension;
                            uploadS3FileService.Upload(file, fileName).then(function (result) {
                                $scope.importPOObject.fileName = fileName;
                                // Mark as success
                                poScreenService.ImportPurchaseOrder($scope.importPOObject).then(function (resp) {
                                    if (resp.data.success) {
                                        $scope.importResponse = resp.data.data;
                                        $scope.failedResponse = [];
                                        $('#importPOResponseModal').modal(
                                        {
                                                backdrop: 'static',
                                                keyboard: false,
                                                show: true
                                        });
                                    }
                                    else {
                                        $scope.importResponse = [];
                                        $scope.failedResponse = resp.data.data;

                                        $('#importPOResponseModal').modal(
                                        {
                                                backdrop: 'static',
                                                keyboard: false,
                                                show: true
                                        });
                                    }

                                    Swal.fire({
                                        position: "top-right",
                                        icon: resp.data.messageType === 1 ? "success" : "error",
                                        title: resp.data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                });

                                //file.Success = true;
                                //console.log("success");
                            }, function (error) {
                                // Mark the error
                                $scope.Error = error;
                                Swal.fire({
                                    position: "top-right",
                                    icon: "error",
                                    title: $scope.Error,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }, function (progress) {
                                // Write the progress as a percentage
                                file.Progress = (progress.loaded / progress.total) * 100
                            });
                        }

                        //if (file) {
                        //    file.upload = Upload.upload({
                        //        url: 'api/file',
                        //        method: 'POST',
                        //        data: $scope.upload
                        //    });

                        //    file.upload.then(function (resp) {
                        //        $timeout(function () {
                        //            if (resp.data.success) {
                        //                $scope.importResponse = resp.data.data;
                        //                $scope.failedResponse = [];
                        //                $('#importPOResponseModal').modal(
                        //                    {
                        //                        backdrop: 'static',
                        //                        keyboard: false,
                        //                        show: true
                        //                    });
                        //            }
                        //            else {
                        //                $scope.importResponse = [];
                        //                $scope.failedResponse = resp.data.data;

                        //                $('#importPOResponseModal').modal(
                        //                    {
                        //                        backdrop: 'static',
                        //                        keyboard: false,
                        //                        show: true
                        //                    });
                        //            }

                        //            Swal.fire({
                        //                position: "top-right",
                        //                icon: resp.data.messageType === 1 ? "success" : "error",
                        //                title: resp.data.message,
                        //                showConfirmButton: false,
                        //                timer: 2000
                        //            });
                        //        });
                        //    }, function (resp) {
                        //        //if (resp.status > 0)
                        //        //    $scope.errorMsg = resp.status + ': ' + resp.data;
                        //    }, function (evt) {
                        //        file.progress = Math.min(100, parseInt(100.0 *
                        //            evt.loaded / evt.total));
                        //    });
                        //}
                    };

                }]
        });

}(window.angular));