(function (angular) {
    "use strict";

    angular.module('myApp').component('processWishlist',
        {
            templateUrl: '/wishlist/views/processWishlist.tpl.html',
            controller: ['$scope', 'rishviList','wishlistService',
                function ($scope, rishviList, wishlistService) {

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/wishlist/';
                    $scope.list.module = 'processWishlist';
                    $scope.list.filters.wishlistStatus = 1;
                    $scope.list.load();

                    $scope.deleteWhishlistOrder = function () {

                        $scope.deleteDto = {};
                        $scope.deleteDto.ids = [];

                        var set = $('#processWishList').find('td:first-child .checkable');

                        $(set).each(function () {
                            var checked = $(this).is(':checked');
                            if (checked) {
                                $scope.deleteDto.ids.push($(this).val());
                            }
                        });
                        console.log($scope.deleteDto);
                        if ($scope.deleteDto.ids.length > 0) {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You want to delete selected WhishList?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!"
                            }).then(function (result) {
                                console.log(result);
                                if (result.value) {
                                    if ($scope.deleteDto.ids.length > 0) {
                                        wishlistService.delete($scope.deleteDto).then(function (resp) {
                                            Swal.fire({
                                                position: "top-right",
                                                icon: resp.data.messageType === 1 ? "success" : "error",
                                                title: resp.data.message,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });
                                            if (resp.data.success) {
                                                $scope.list.load();
                                            }
                                        });
                                    }
                                    else {
                                        Swal.fire({
                                            position: "top-right",
                                            icon: "error",
                                            title: "Select atleast one item to delete!",
                                            showConfirmButton: false,
                                            timer: 2000
                                        });
                                    }
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                position: "top-right",
                                icon: "error",
                                title: "Select atleast one item to delete!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    }

                    $scope.unprocessWhishlist = function () {

                        $scope.unprocessDto = {};
                        $scope.unprocessDto.ids = [];

                        var set = $('#processWishList').find('td:first-child .checkable');

                        $(set).each(function () {
                            var checked = $(this).is(':checked');
                            if (checked) {
                                $scope.unprocessDto.ids.push($(this).val());
                            }
                        });

                        console.log($scope.unprocessDto.ids);

                        if ($scope.unprocessDto.ids.length > 0) {
                            Swal.fire({
                                title: "Are you sure?",
                                text: "You want to unprocess selected WhishList?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!"
                            }).then(function (result) {
                                console.log(result);
                                if (result.value) {
                                    if ($scope.unprocessDto.ids.length > 0) {
                                        debugger;
                                        wishlistService.bulkUnProcess($scope.unprocessDto).then(function (resp) {
                                            Swal.fire({
                                                position: "top-right",
                                                icon: resp.data.messageType === 1 ? "success" : "error",
                                                title: resp.data.message,
                                                showConfirmButton: false,
                                                timer: 2000
                                            });
                                            if (resp.data.success) {
                                                $scope.list.load();
                                            }
                                        });
                                    }
                                    else {
                                        Swal.fire({
                                            position: "top-right",
                                            icon: "error",
                                            title: "Select atleast one item to unprocess!",
                                            showConfirmButton: false,
                                            timer: 2000
                                        });
                                    }
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                position: "top-right",
                                icon: "error",
                                title: "Select atleast one item to unprocess!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                    }

                }]
        });

}(window.angular));